<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Transaction Ether Pool</h3>
          <div class="flex gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." type="text"
              @keyup="search()" />
            <button class="btn btn-primary btn-sm" @click="getInfo()">Pool Consolidation</button>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>Account ID</th>
                <th>Address</th>
                <th>IsInUse</th>
                <th>IsEth</th>
                <th>IsPoolMaster</th>
                <th>Private Key</th>
                <th>Path</th>
                <th>Created On</th>
                <th>Updated On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.AccountID }}</td>
                  <td class="no-wrap">{{ record.Address }}</td>
                  <td>
                    <span v-html="formatBoolean(record.IsInUse)"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.IsEth)"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.IsPoolMaster)"></span>
                  </td>
                  <td class="no-wrap">{{ record.PrivateKey }}</td>
                  <td>{{ record.Path }}</td>
                  <td class="no-wrap">{{ formatDate(record.CreatedOn) }}</td>
                  <td class="no-wrap">{{ formatDate(record.UpdatedOn) }}</td>
                  <td class="action-sm pr-16"></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="10">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";

export default {
  name: "TransactionEtherPool",
  components: {
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getPaginatedRecords(1);
      }, 500);
    },
    getPaginatedRecords(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=transaction_ether_pool`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getInfo() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.searching = "Loading...";
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          Name: "B",
          AltCoinID: 101,
          Secure: "AA",
          ETHPOOLCLEAN: "",
        },
      }).then(response => {
        this.searching = "";
      }).catch(error => {
        this.searching = "";
        console.log(error);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
  },
  mounted() {
    this.getPaginatedRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
